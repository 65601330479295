var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Data Display',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" For displaying tabular data, "),_c('code',[_vm._v("<b-table>")]),_vm._v(" supports pagination, filtering, sorting, custom rendering, various style options, events, and asynchronous data. For simple display of tabular data without all the fancy features, BootstrapVue provides two lightweight alternative components "),_c('code',[_c('a',{attrs:{"href":"https://bootstrap-vue.org/docs/components/table#light-weight-tables","target":"_blank"}},[_vm._v("<b-table-lite>")])]),_vm._v(" and "),_c('code',[_c('a',{attrs:{"href":"https://bootstrap-vue.org/docs/components/table#simple-tables","target":"_blank"}},[_vm._v("<b-table-simple>")])]),_vm._v(". ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('styling')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('responsive')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('stacked')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('table-caption')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('busy')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('header-footer-custom')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('sticky-headers')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('sticky-columns')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('row-select')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('sorting')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('complete')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }